@import "theme-variables";

.centerChildren {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 3rem 0;
    align-items: center;
    justify-content: center;
    margin-top: 6rem;
    @media only screen and (max-width: 768px) {
        margin-top: 3rem;
        padding: 2rem 0 3rem 0;
    }
}

.mainContent {
    width: 70%;
    max-width: 40rem;
    min-height: 30rem;
    border-radius: 2rem;
    border-radius: 50px;
    box-shadow: $light-shadow-lg;
    padding: 3em;
    :global(body.dark) & {
        box-shadow: $dark-shadow-lg;
    }
    @media only screen and (max-width: 768px) {
        width: 90%;
        margin: 0;
        padding: 0rem;
        box-shadow: none;
        :global(body.dark) & {
            box-shadow: none;
        }
    }
}
