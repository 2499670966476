@import "theme-variables";

.headerContainer {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
    font-size: 1rem;
    height: 3rem;
    box-shadow: 0px 5px 5px $ligh-sh-1;
    background-color: $light-bg;

    :global(body.dark) & {
        background-color: $dark-bg;
        box-shadow: 0px 5px 5px $dark-sh-1;
    }
}
.logo {
    font-size: 1rem;
    font-weight: 700;
    color: #000;
    text-decoration: none;
    :global(body.dark) & {
        color: #fff;
    }
}
.headerItems {
    align-items: center;
    display: flex;
    gap: 1rem;
    margin-right: 2rem;
    @media only screen and (max-width: 768px) {
        font-size: 0.9rem;
    }
}
.link {
    text-decoration: none;
    font-weight: 700;
    color: $light-text;
    :global(body.dark) & {
        color: $dark-text;
    }
    &:hover {
        color: #000;
        :global(body.dark) & {
            color: #ffffff;
        }
    }
}

.infoBar {
    z-index: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    min-height: 1.5rem;
    text-align: center;
    color: #fff;
    font-size: 0.8rem;
    position: fixed;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
}

.close {
    cursor: pointer;
    padding-right: 0.5rem;
}
.themeSwitch {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 0.9rem;
    cursor: pointer;
}
