.container {
    height: 15px;
    width: 105px;
    display: flex;
    position: relative;
    .circle {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background-color: #4c4e52;
        animation: move 500ms linear 0ms infinite;
        margin-right: 30px;

        &:first-child {
            position: absolute;
            top: 0;
            left: 0;
            animation: grow 500ms linear 0ms infinite;
        }

        &:last-child {
            position: absolute;
            top: 0;
            right: 0;
            margin-right: 0;
            animation: grow 500ms linear 0s infinite reverse;
        }
    }
    @keyframes grow {
        from {
            transform: scale(0, 0);
            opacity: 0;
        }
        to {
            transform: scale(1, 1);
            opacity: 1;
        }
    }

    @keyframes move {
        from {
            transform: translateX(0px);
        }
        to {
            transform: translateX(45px);
        }
    }
}

.smallContainer {
    margin: auto;
    width: 70px;
    display: flex;
    position: relative;
    .smallCircle {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #fff;
        animation: move 500ms linear 0ms infinite;
        margin-right: 20px;

        &:first-child {
            position: absolute;
            top: 0;
            left: 0;
            animation: grow 500ms linear 0ms infinite;
        }

        &:last-child {
            position: absolute;
            top: 0;
            right: 0;
            margin-right: 0;
            animation: grow 500ms linear 0s infinite reverse;
        }
    }
    @keyframes grow {
        from {
            transform: scale(0, 0);
            opacity: 0;
        }
        to {
            transform: scale(1, 1);
            opacity: 1;
        }
    }

    @keyframes move {
        from {
            transform: translateX(0px);
        }
        to {
            transform: translateX(30px);
        }
    }
}
